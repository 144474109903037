import React, { useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import Cards from "../resusableComponent/Cards";
import IconButton from "@mui/material/IconButton";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import "./DashboardList.css";
import SingleActionDialog from "../resusableComponent/SingleActionDialog";
import navigationImage from "./navigationPage.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useMsal } from "@azure/msal-react";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";

const back = "back";
const subHeading = "Lite - Standard";

function DashboardList(props) {
  const headerAnalytics = "Sales Insight Dashboard";
  const headerInventory = "Inventory Insight Dashboard";
  const headerPendingOrder = "Pending Order Report";
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;
  const [singleOpen, setSingleOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  
  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({name: 'Lite - Standard'});
    }
  }, []);

  const analyticsIcon = (
    <IconButton aria-label="DashBoard" sx={{ padding: "2px" }}>
      <InsertChartIcon sx={{ color: "#AB1E34", fontSize: "40px" }} />
    </IconButton>
  );

  const checkAccess = (cardType) => {
    if (cardType === "powerbi") {
      
      if (sessionStorage.getItem("pbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Sales Insight Dashboard");
        return false;
      }
    }
    if (cardType === "inventorypowerbi") {
      
      if (sessionStorage.getItem("inventoryPbiAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Inventory Insight Dashboard");
        return false;
      }
    }
    if (cardType === "pendingorderreport") {
      
      if (sessionStorage.getItem("pendingOrderReportAccess") === "true") {
        return true;
      } else {
        setMsg("Please Contact us to get Access to Pending Order Report");
        return false;
      }
    }
    
  };

  const onClickCard = (path) => {
    props.history.push(path);
  };

  const onClickBack = (path) => {
    props.history.push(path);
  };

  return (
    <>
      <SingleActionDialog open={singleOpen} msg={msg} setOpen={setSingleOpen} />
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator"
        />
      </div>
      <Box 
          style={{
            backgroundImage: `url(${navigationImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }} 
          sx={{ display: { xs: 'none', md: 'flex' } }}>
          <div style={{ height: "45vh" }}></div>
      </Box>
      <Grid
        container
        direction="row"
      >

        {
          sessionStorage.getItem("pbiAccess") === "true" ?
          <Cards
            header={headerAnalytics}
            isImage="false"
            //subHeader={subHeaderAnalytics}
            icon={analyticsIcon}
            path="/connect_client/navigator/lite_standard/sales_insight_dashboard"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="powerbi"
            onClickCard={onClickCard}
            hoverMsg="Daily Sales Insights for better Business planning"
          /> : <></>
        }

        {
          sessionStorage.getItem("inventoryPbiAccess") === "true" ?
          <Cards
            header={headerInventory}
            isImage="false"
            //subHeader={subHeaderInventory}
            icon={analyticsIcon}
            path="/connect_client/navigator/lite_standard/inventory_insight_dashboard"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="inventorypowerbi"
            onClickCard={onClickCard}
            hoverMsg="Daily Inventory Insights for better Business planning"
          /> : <></>
        }

        {
          sessionStorage.getItem("pendingOrderReportAccess") === "true" ?
          <Cards
            header={headerPendingOrder}
            isImage="false"
            //subHeader={subHeaderInventory}
            icon={analyticsIcon}
            path="/connect_client/navigator/lite_standard/pending_order_report"
            setOpen={setSingleOpen}
            checkAccess={checkAccess}
            cardType="pendingorderreport"
            onClickCard={onClickCard}
            hoverMsg="Daily Pending Order Report for better Business planning"
          /> : <></>
        }
      </Grid>
      <br />
      <BottomInfoBar />
    </>
  );
}

export default DashboardList;
