import React, { useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import { useMsal } from "@azure/msal-react";
import "./pendingOrderReport.css";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";

const back = "back";
const subHeading = "Pending Order Report";

function PendingOrderReport(props) {
  
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;

  const onClickBack = (path) => {
    props.history.push(path);
  };

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({name: 'Pending Order Report'});
    }
  }, []);

  return (
    <>
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          onClickBack={onClickBack}
          path="/connect_client/navigator/lite_standard"
        />
      </div>
      <BottomInfoBar />
    </>
  );
}

export default PendingOrderReport;
